import { baseURL } from '../config'
import axios from 'axios';
import { toast } from "react-toastify";


export async function fetchApi(url,body) {
    try {
      const response = await axios.get(`${baseURL}${url}`,body);
      // Check if response exists and has data
      if (!response || !response.data) {
        toast.error('No response received from server');
        throw new Error('No response received');
      }
  
      // If there's an error message in the response, show it and throw
      if (response.data.errorMessage) {
        toast.error(response.data.errorMessage);
        throw new Error(response.data.errorMessage);
      }
  
      // If status is not 200 or message is not success, show error
      if (response.status !== 200 || response.data.message !== "success") {
        const errorMsg = response.data.errorMessage || 'Operation failed';
        toast.error(errorMsg);
        throw new Error(errorMsg);
      }
  
      return response.data;
  
    } catch (error) {
      // Handle axios errors
      if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
      } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
      } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
      }
    }
}
  

export const getAllPortfolio = async (token) => {
    try {   
        const response = await fetchApi('portfolio',{
            headers: {
                Authorization: token,
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching portfolio:', error);
        throw error;
    }
}

export async function createPortfolio(token , {albumName , images , proof}) {
    try {
    
        const formData = new FormData();
        formData.append("albumName", albumName);            
        images.forEach((image) => {
            formData.append("images", image.file);
        });
        if (proof) {
            formData.append("proof", proof);
        }
        const response = await axios.post(`${baseURL}portfolio/create`, formData,
       { 
        headers: {
            Authorization: token,
          },
      }
      );
  
      // Check if response exists and has data
      if (!response || !response.data) {
        toast.error('No response received from server');
        throw new Error('No response received');
      }
  
      // If there's an error message in the response, show it and throw
      if (response.data.errorMessage) {
        toast.error(response.data.errorMessage);
        throw new Error(response.data.errorMessage);
      }
  
      // If status is not 200 or message is not success, show error
      if (response.status !== 200 || response.data.message !== "success") {
        const errorMsg = response.data.errorMessage || 'Operation failed';
        toast.error(errorMsg);
        throw new Error(errorMsg);
      }
  
      return response.data;
  
    } catch (error) {
      // Handle axios errors
      if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
      } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
      } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
      }
    }
}
export const getAllPortfolioImages = async (token,portfolioId) => {
  try {   
        const response = await fetchApi(`portfolio/images/${portfolioId}`,{
          headers: {
              Authorization: token,
          },
      });
      return response;
  } catch (error) {
      console.error('Error fetching portfolio:', error);
      throw error;
  }
}

export const deletePortfolioImage = async (token,portfolioId,imageId) => {
  try {   
    const response = await axios.delete(`${baseURL}portfolio/images/${portfolioId}/${imageId}`,{
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    console.error('Error deleting portfolio image:', error);
    throw error;
  }
} 

export const deletePortfolio = async (token,portfolioId) => {
  try {
    const response = await axios.delete(`${baseURL}portfolio/${portfolioId}/delete`,{
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    console.error('Error deleting portfolio:', error);
    throw error;
  }
} 

export async function AddPortfolioImage(token , portfolioId , images) {
  try {
  
    const formData = new FormData();
    images.forEach((image) => {
        formData.append("images", image.file);
    });

      const response = await axios.post(`${baseURL}portfolio/images/${portfolioId}`, formData,
     { 
      headers: {
          Authorization: token,
        },
    }
    );

    // Check if response exists and has data
    if (!response || !response.data) {
      toast.error('No response received from server');
      throw new Error('No response received');
    }

    // If there's an error message in the response, show it and throw
    if (response.data.errorMessage) {
      toast.error(response.data.errorMessage);
      throw new Error(response.data.errorMessage);
    }

    // If status is not 200 or message is not success, show error
    if (response.status !== 200 || response.data.message !== "success") {
      const errorMsg = response.data.errorMessage || 'Operation failed';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    }

    return response.data;

  } catch (error) {
    // Handle axios errors
    if (error.response) {
      // Server responded with error status
      const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    } else if (error.request) {
      // Request made but no response
      toast.error('No response from server');
      throw new Error('No response from server');
    } else {
      // Other errors
      toast.error(error.message || 'Request failed');
      throw error;
    }
  }
}

export async function AddPortfolioVideo(token , portfolioId , videoLink , coverImage) {
  try {
  
    const formData = new FormData();
    formData.append("videoLink", videoLink);
    formData.append("coverImage", coverImage);

      const response = await axios.post(`${baseURL}portfolio/videos/${portfolioId}`, formData,
     { 
      headers: {
          Authorization: token,
        },
    }
    );

    // Check if response exists and has data
    if (!response || !response.data) {
      toast.error('No response received from server');
      throw new Error('No response received');
    }

    // If there's an error message in the response, show it and throw
    if (response.data.errorMessage) {
      toast.error(response.data.errorMessage);
      throw new Error(response.data.errorMessage);
    }

    // If status is not 200 or message is not success, show error
    if (response.status !== 200 || response.data.message !== "success") {
      const errorMsg = response.data.errorMessage || 'Operation failed';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    }

    return response.data;

  } catch (error) {
    // Handle axios errors
    if (error.response) {
      // Server responded with error status
      const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    } else if (error.request) {
      // Request made but no response
      toast.error('No response from server');
      throw new Error('No response from server');
    } else {
      // Other errors
      toast.error(error.message || 'Request failed');
      throw error;
    }
  }
}