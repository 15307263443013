import React, { useEffect } from 'react';
import styles from './subscription.module.css';
import { useSelector } from 'react-redux';
import { subscriptionDetails } from '../../api/login';
import { useState } from 'react';
import MoreFeatures from './MoreFeatures';
import { subscriptionPlansBuy } from '../../api/login';

function Subscription() {
  const token = useSelector((state) => state.login.token);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const res = await subscriptionDetails(token);
        setSubscriptionPlans(res.data);
      } catch (error) {
        console.error('Error fetching subscription details:', error); // Handle errors
      }
    };

    fetchSubscriptionDetails();
  }, [token]); 
  console.log(subscriptionPlans); 
  const buyPlan = async (plan) => {
    try {
     
      await subscriptionPlansBuy(token, plan);
      console.log(plan)
    } catch {
      
    }
};
  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div className={styles.header}>
          <h1>Plans & Pricing</h1>
        </div>
        <div className={styles.planSelect}>

          <p>Choose a plan that suits for your business</p>
          </div>
        <div className={styles.planSelection}>
          <div>
            <select>
              <option>1 Year</option>
              <option>2 Years</option>
            </select>
            <select>
              <option>Desktop + Mobile</option>
              <option>Desktop Only</option>
            </select>
          </div>
        </div>
        <div className={styles.plansContainer}>
        {subscriptionPlans.map((plan, index) => (
  <div key={index} className={`${styles.planCard} ${styles[plan.color]}`}>
    <div className={styles.planInfo}>
      <span className={styles.duration}>{plan.duration} Months</span>
      <span className={styles.device}>Desktop + Mobile</span>
    </div>
    <div className={styles.planHeader}>
    <div className={styles.planIcon}> {plan.color ==='silver' ?  '🥈' : '👑'}</div> 

      <div className={styles.planType}>{plan.name}</div>
              <div className={styles.planPrice}>{plan.price-plan.offer === plan.price ? null: <span style={{   textDecoration: 'line-through' ,marginRight: '8px',
      paddingBottom: '2px',
 color: 'white' ,fontSize: '28px' }}>
    ₹{plan.price}
  </span> }
  <span style={{ color: 'black', marginLeft: '18px' , fontSize: '28px' }}>
    ₹{plan.price-plan.offer}
  </span></div>
    </div>
    <button className={styles.buyButton} onClick={() => buyPlan(plan.id)}>Buy {plan.name}</button>
    <div className={styles.features}>
      {plan.features.slice(0,4).map((feature, featureIndex) => (
        <div key={featureIndex} className={styles.feature}>
          <span className={styles.checkmark}>✓</span>
          {feature}
        </div>
      ))}
              {plan.features.length > 4 && (
                <div className={styles.featureButton}>
                  <button onClick={() => {
                    setSelectedPlan(plan);
                    setIsModalVisible(true);
                  }}>
                    +{plan.features.length - 4} More Features
                  </button>
                </div>
              )}
    </div>
  </div>
))}


        </div>
      </div>
      <MoreFeatures
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        plan={selectedPlan}
      
      />
    </div>
  );
}

export default Subscription; 