import { baseURL } from '../config'
import axios from 'axios';
import { toast } from "react-toastify";


export async function fetchApi(url,body) {
    try {
      const response = await axios.get(`${baseURL}${url}`,body);
      // Check if response exists and has data
      if (!response || !response.data) {
        toast.error('No response received from server');
        throw new Error('No response received');
      }
  
      // If there's an error message in the response, show it and throw
      if (response.data.errorMessage) {
        toast.error(response.data.errorMessage);
        throw new Error(response.data.errorMessage);
      }
  
      // If status is not 200 or message is not success, show error
      if (response.status !== 200 || response.data.message !== "success") {
        const errorMsg = response.data.errorMessage || 'Operation failed';
        toast.error(errorMsg);
        throw new Error(errorMsg);
      }
  
      return response.data;
  
    } catch (error) {
      // Handle axios errors
      if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
      } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
      } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
      }
    }
}
  




export async function listAllCategories(token ) {
    try {
        const body = {
            token
        }
        const response = await fetchApi('ecom/all-categories', {
            headers: {
                Authorization: token,
            }
        })
        return response
    } catch (error) {
        toast.error(error.message);
        throw error;
    }
}

export async function listAllProducts(token, params) {   
    try {
        const { search='',isNew, page = 1, limit = 1000 } = params;
        
        // Build query string
        const queryParams = new URLSearchParams({
            ...(search && search ),
            ...(isNew !== undefined && { isNew }),
            page: page.toString(),
            limit: limit.toString()
        }).toString();

        const response = await fetchApi(`ecom/marketplace?${queryParams}`, {
            headers: {
                Authorization: token,
            }
        });
        
        return response;
    } catch (error) {
        toast.error(error.message);
        throw error;
    }
}   

export async function productDetail(token, productId) {
    try {
        const response = await fetchApi(`ecom/products/${productId}/details`, {
            headers: {
                Authorization: token,
            }
        });
        return response;
    } catch (error) {
        toast.error(error.message);
        throw error;
    }
}
  
export async function requestContact(token, productId) {
    try {
    
  
        const response = await axios.post(`${baseURL}ecom/products/${productId}/contact`, {},
       { 
        headers: {
            Authorization: token,
          },
      }
      );
  
      // Check if response exists and has data
      if (!response || !response.data) {
        toast.error('No response received from server');
        throw new Error('No response received');
      }
  
      // If there's an error message in the response, show it and throw
      if (response.data.errorMessage) {
        toast.error(response.data.errorMessage);
        throw new Error(response.data.errorMessage);
      }
  
      // If status is not 200 or message is not success, show error
      if (response.status !== 200 || response.data.message !== "success") {
        const errorMsg = response.data.errorMessage || 'Operation failed';
        toast.error(errorMsg);
        throw new Error(errorMsg);
      }
  
      return response.data;
  
    } catch (error) {
      // Handle axios errors
      if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
       
      } else if (error.request) {
        // Request made but no response
        throw new Error('No response from server');
      } else {
        // Other errors
        throw error;
      }
    }
}
  
 
export async function storeDetails(token, vendorId, params = {}) {
  try {
    const { search = '', isNew } = params;

    const queryParams = new URLSearchParams({
      ...(search && { search }),      // Include search if provided
      ...(isNew !== undefined && { isNew }),  // Include isNew if provided
    }).toString();

    const response = await fetchApi(`ecom/store-details/${vendorId}?${queryParams}`, {
      headers: {
        Authorization: token,
      }
    });
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
}

export async function   listAllInventory(token,params) {
  try {
    const { search='', categoryId ,isNew, page = 1, limit = 1000 } = params;
       
    const queryParams = new URLSearchParams({
      ...(search && { search }),      // Include search if provided
      ...(categoryId && { categoryId }),  // Include categoryId if provided
      ...(isNew !== undefined && { isNew }),  // Include isNew if provided
      page: page.toString(),
      limit: limit.toString()
  }).toString();
    
        const response = await fetchApi(`ecom/products?${queryParams}`, {
            headers: {
                Authorization: token,
            }
        });
        return response;
    } catch (error) {
        toast.error(error.message);
        throw error;
    }
}




function base64ToBlob(base64String, mimeType) {
  const byteCharacters = atob(base64String.split(',')[1]);  // Decode base64 to binary
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}


export async function AddEcomInventoryApi(token, formData) {
  try {
   
    const response = await axios.post(`${baseURL}ecom/products`, formData, {
      headers: {
        Authorization: token,
      },
    });

    // Check if response exists and has data
    if (!response || !response.data) {
      toast.error('No response received from server');
      throw new Error('No response received');
    }

    // If there's an error message in the response, show it and throw
    if (response.data.errorMessage) {
      toast.error(response.data.errorMessage);
      throw new Error(response.data.errorMessage);
    }

    // If status is not 200 or message is not success, show error
    if (response.status !== 200 || response.data.message !== "success") {
      const errorMsg = response.data.errorMessage || 'Operation failed';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    }

    return response.data;

  } catch (error) {
    // Handle axios errors
    if (error.response) {
      // Server responded with error status
      const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    } else if (error.request) {
      // Request made but no response
      toast.error('No response from server');
      throw new Error('No response from server');
    } else {
      // Other errors
      toast.error(error.message || 'Request failed');
      throw error;
    }
  }
}


export async function editInventory(token, productId, formData) {
  try {
    const response = await axios.put(
      `${baseURL}ecom/products/${productId}`,
      formData,
      {
        headers: {
          'Authorization': token,
        }
      }
    );

    if (!response || !response.data) {
      throw new Error('No response received from server');
    }

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  } catch (error) {
    const errorMsg = error.response?.data?.errorMessage || error.message || 'Failed to update inventory';
    toast.error(errorMsg);
    throw new Error(errorMsg);
  }
}




export async function addRating(token, productId , rating, review) {
  try {
    const response = await axios.post(
      `${baseURL}ecom/products/${productId}/rating`, {
        rating,review
      } ,    
      {
        headers: {
          'Authorization': token,
        }
      }
    );

    if (!response || !response.data) {
      throw new Error('No response received from server');
    }

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  } catch (error) {
    const errorMsg = error.response?.data?.errorMessage || error.message || 'Failed to update inventory';
    toast.error(errorMsg);
    throw new Error(errorMsg);
  }
}

export async function listAllMyProducts(token, params) {   
  try {
      const { search='', categoryId, isNew, page = 1, limit = 1000 } = params;
      
      // Build query string
      const queryParams = new URLSearchParams({
          ...(search && { search }),
          ...(categoryId && { categoryId }),
          ...(isNew !== undefined && { isNew }),
          page: page.toString(),
          limit: limit.toString()
      }).toString();

      const response = await fetchApi(`ecom/products?${queryParams}`, {
          headers: {
              Authorization: token,
          }
      });
      
      return response;
  } catch (error) {
      toast.error(error.message);
      throw error;
  }
} 

export async function getWishlist(token) {
  try {
    const response = await axios.get(`${baseURL}ecom/products/get/wishlist`, {
      headers: {
        Authorization: token,
      }
    });

    if (!response || !response.data) {
      throw new Error('No response received from server');
    }

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  } catch (error) {
    const errorMsg = error.response?.data?.errorMessage || error.message || 'Failed to fetch wishlist';
    toast.error(errorMsg);
    throw new Error(errorMsg);
  }
}

export async function addWishlist(token, productId) {
  try {
    const response = await axios.post(
      `${baseURL}ecom/products/${productId}/wishlist`, 
      {}, 
      {
        headers: {
          Authorization: token,
        }
      }
    );

    if (!response || !response.data) {
      throw new Error('No response received from server');
    }

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  } catch (error) {
    const errorMsg = error.response?.data?.errorMessage || error.message || 'Failed to add to wishlist';
    toast.error(errorMsg);
    throw new Error(errorMsg);
  }
}

export async function removeWishlist(token, productId) {
  try {
    const response = await axios.delete(`${baseURL}ecom/products/${productId}/wishlist`, {
      headers: {
        Authorization: token,
      }
    });

    if (!response || !response.data) {
      throw new Error('No response received from server');
    }

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  } catch (error) {
    const errorMsg = error.response?.data?.errorMessage || error.message || 'Failed to remove from wishlist';
    toast.error(errorMsg);
    throw new Error(errorMsg);
  }
}