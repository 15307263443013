import React, { useState } from "react";
import styles from "./CreateAlbum.module.css";
import { useSelector } from "react-redux";
import { createPortfolio } from "../../api/portfolio";
import { toast } from "react-toastify";

function CreateAlbum({ onClose, open  , updatePortfolio}) {
//   const [albumName, setAlbumName] = useState("");
//   const [images, setImages] = useState([]);
//   const [proof, setProof] = useState({});
  const [portfolio , setPortfolio] = useState({albumName: "" , images: [] , proof: null});
    const token = useSelector((state) => state.login.token);
    const [loading , setLoading] = useState(false);

    const handleImageUpload = (e) => {
      e.preventDefault();
    const files = Array.from(e.target.files);

    const imagePreviews = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      url: URL.createObjectURL(file),
    }));
console.log(imagePreviews);
    setPortfolio({...portfolio , images: [...portfolio.images, ...imagePreviews]});
  };

  const handleProofUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const url = ({
            file,
            preview: URL.createObjectURL(file),
            url : URL.createObjectURL(file),
        })
        console.log(url);
        setPortfolio((prevData) => ({ ...prevData, proof: url }));
      }
  };

  const handleSave = async () => {
    try {
      setLoading(true);

     
      if (portfolio.albumName.length === 0 && portfolio.images.length === 0) {
        alert("Please enter album name and upload images");
        return;
      } else {
            const data = await createPortfolio(token,portfolio);
        if (data.message === 'success') {
          toast.success("Portfolio created successfully");
            onClose();
          updatePortfolio();
          setPortfolio({albumName: "" , images: [] , proof: null});
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  if (!open) {
    return null;
  }

    if(loading){
      return <div className={styles.loading}>Loading...</div>;
    }
  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          ×
        </button>
        <h2>Create Folder</h2>
        <div className={styles.formGroup}>
          <label>Album</label>
          <input
            type="text"
            placeholder="Enter your album name"
            value={portfolio.albumName}
            onChange={(e) => setPortfolio({...portfolio , albumName: e.target.value})}
          />
          <small>You can add up to 5 images</small>
          <input
            type="file"
            accept="image/*"
            multiple
                    onChange={(e) => handleImageUpload(e)}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Proof Of Event</label>
          <input type="file" accept=".png, .jpg" onChange={handleProofUpload} />
        </div>
        <button className={styles.saveButton} onClick={handleSave} disabled={loading}>
          Save
        </button>
      </div>
    </div>
  );
}

export default CreateAlbum;
