import React, { useState } from "react";
import styles from "./Portfolio.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CreateAlbum from "./CreateAlbum";
import { getAllPortfolio , deletePortfolio } from "../../api/portfolio";
import { useEffect } from "react";
import deletePortfolioicon from "../../Assest/img/deletePortfolio.svg";
import DeletePortfolioModal from './DeletePortfolioModal';
import { toast } from "react-toastify";


function Portfolio() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("portfolio");
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreateAlbumOpen, setIsCreateAlbumOpen] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);   
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState(null);
  
  const token = useSelector((state) => state.login.token);

  const fetchPortfolio = async () => {    
    try {
      const data = await getAllPortfolio(token);
      setPortfolioData(data.data.portfolios);
    } catch (error) {
      console.error('Error fetching portfolio:', error);
    }
  }

  
  useEffect(() => {
    fetchPortfolio();   
  }, []);

  const updatePortfolio = async () => {
    await fetchPortfolio();
  }

  const handleCreateAlbum = () => {
    setIsCreateAlbumOpen(true);
  };

  const closeCreateAlbum = () => {
    setIsCreateAlbumOpen(false);
  };

  const getStatusButton = (status) => {
    switch (status?.toLowerCase()) {
      case "no verification":
        return (
          <button className={styles.applyButton}>No Verification</button>
        );
      case "not verified":
        return <button className={styles.appliedButton}>Not Verified</button>;
      case "applied":
        return <button className={styles.rejectedButton}>Applied</button>;
      case "rejected":
        return <button className={styles.rejectedButton}>Rejected</button>;
      default:


        return <button className={styles.applyButton}>No Status</button>;
    }
  };
  console.log(portfolioData);
  const handleShowAllImage = (id) => {
    navigate(`/showallimage` ,{state: {id}});
    console.log(id);
  };

  const handleDeletePortfolio = (id, e) => {
    e.stopPropagation();
    setSelectedPortfolioId(id);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    console.log('hgc')
    try {
     const response = await deletePortfolio(token, selectedPortfolioId);
      if(response.status === 200){
        setIsDeleteModalOpen(false);
        await fetchPortfolio();
      }else{
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error deleting portfolio:', error);
    }
  };
  
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.tabs}>
          <button
            className={`${styles.tab} ${
              activeTab === "portfolio" ? styles.activeTab : ""
            }`}
            onClick={() => setActiveTab("portfolio")}
          >
            Portfolio
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "verification portfolio" ? styles.activeTab : ""
            }`}

            onClick={() => setActiveTab("verification portfolio")}
          >
            Verification Portfolio
          </button>
        </div>

        <div className={styles.searchBar}>
          <input
            type="search"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className={styles.categoryGrid}>
        <div className={styles.categoryCard} onClick={handleCreateAlbum}>
          <div className={styles.plusIcon}>+</div>
          <p>Create Album</p>
        </div>

        {portfolioData.map((item) => (
          <div key={item.id} className={styles.categoryCard} onClick={() => handleShowAllImage(item.portfolioId)}>
            <img className={styles.deletePortfolio} src={deletePortfolioicon} onClick={(e) => handleDeletePortfolio(item.portfolioId, e)}></img>
            <img
              src={item.imageUrl}
              alt={item.name}
              className={styles.categoryImage}
            />
            <div className={styles.categoryContent}>
              <h3>{item.albumName}</h3>
            </div>
          <div className={styles.status}>
              <button className={item.verificationStatus === 'Applied'  ? styles.appliedButton: item.verificationStatus === 'Rejected' ? styles.rejectedButton : item.verificationStatus === 'Not Verified' ? styles.applyButton : styles.appliedButton}>{item.verificationStatus}</button>
          </div>
          </div>
        ))}
      </div>
      <CreateAlbum onClose={closeCreateAlbum} open={isCreateAlbumOpen} updatePortfolio={updatePortfolio}/>
      <DeletePortfolioModal 
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
}

export default Portfolio;
