import React, { useState } from "react";
import styles from "./CreateAlbum.module.css";
import { useSelector } from "react-redux";
import { AddPortfolioVideo } from "../../api/portfolio";
import { toast } from "react-toastify";

function AddVideo({ onClose, open, updatePortfolio , portfolioId}) {
  const [videoLink, setVideoLink] = useState('');
  const token = useSelector((state) => state.login.token);
  const [loading, setLoading] = useState(false);
    const [coverImage, setCoverImage] = useState(null);
    
    const handleVideoLink = (e) => {
        e.preventDefault();
        console.log(e.target.value);
        setVideoLink(e.target.value);
    }

  const handleCoverImage = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    setCoverImage(files[0]);
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      if (videoLink === '' && coverImage === null) {
        alert("Please enter video link and upload cover photo");
        return;
      } else {
            const data = await AddPortfolioVideo(token, portfolioId, videoLink.toString() , coverImage);
        if (data.message === "success") {
          toast.success(data.message);
          onClose();
          updatePortfolio();
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  if (!open) {
    return null;
  }

  if (loading) {
    return <div className={styles.loading}>Loading...</div>;
  }
  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          ×
        </button>
        <h2>Add Video Link</h2>
        <div className={styles.formGroup}>
          <input
            type="text"
            placeholder="Enter video link"
            onChange={(e) => handleVideoLink(e)}
                  />
                
        </div>
              <div>
                  <img src={''} alt="COVER photo" />
                  <input type="file" placeholder="add cover photo"
                  onChange={(e) => handleCoverImage(e)}/>
</div>
        <button
          className={styles.saveButton}
          onClick={handleSave}
          disabled={loading}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default AddVideo;
