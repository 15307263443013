import React from 'react';
import splashscreen from '../../Assest/img/splashscreen.svg';
import './splashscreen.css'

const SplashScreen = ({fadeOut}) => {
  return (
      <div className={`splash-screen  ${fadeOut ? 'fade-out' : ''}`}>
          
          <img src={splashscreen} alt="Logo" className="splash-logo" />
         
      <h1 className="splash-text">Welcome to Renter Book</h1>
    </div>
  );
};

export default SplashScreen;
