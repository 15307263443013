import React  from 'react';
import Layout from '../Layout/Layout';
import ShowAllImage from '../../Portfolio/ShowAllImage';

function ShowAllImageFlow() {


  return (
    <Layout  child={'Portfolio'}>
    <ShowAllImage />
  </Layout>
  )
}

export default ShowAllImageFlow
