import React from 'react';
import Layout from '../Layout/Layout';
import TenderDetail from '../../Tender/TenderDetail';
import styles from './TenderFlow.module.css';

function TenderFlow() {
  return (
    <Layout child={'Tender'}>
      <div className={styles.tenderFlowContainer}>
        <TenderDetail />
      </div>
    </Layout>
  );
}

export default TenderFlow;
