import { baseURL } from "../config";
import axios from "axios";
import { toast } from "react-toastify";

export async function signUp(token ,vendorData) {
    try {
        const formData = new FormData();
        for (const key in vendorData) {
            if (vendorData[key] instanceof File) {
                formData.append(key, vendorData[key], vendorData[key].name);
            } else {
                formData.append(key, vendorData[key]);
            }
        }
 
        const response = await axios.post(`${baseURL}vendor/edit-profile`,formData,{
        headers: {
          Authorization: token,
        }
      })
      
      if (
        response &&
        (response.status === 200) & (response.data.message === "success")
      ) {
        return response.data;
      }else {
        toast.error(`Unexpected response: ${response.data.errorMessage}`);
        console.error("Unexpected response:", response ,response.data.errorMessage);
     
      }
    } catch (error) {
      console.error("Server responded with an error:", error.response);
      toast.error(`Error: ${error.response.data.message || error.response.statusText}`);
   
    throw error;
    }
}
  
export async function getSuperCategory(token) {
    try {
       

   const response = await axios.get(`${baseURL}vendor/super-categories/new`,{
    headers: {
      Authorization: token,
    } 
   })
        if (response && response.status === 200 && response.data.message === 'success') {
            return response.data;
        }else {
            toast.error(`Unexpected response: ${response.data.errorMessage}`);
            console.error("Unexpected response:", response ,response.data.errorMessage);
         
          }
    } catch (error) {
        
      console.error("Server responded with an error:", error);
      toast.error(`Error: ${error || error}`);
   
    throw error;
    }
}