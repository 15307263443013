import React, { useState , useEffect} from 'react';
import styles from './Tender.module.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import tenderImg from '../../Assest/img/tender (1) 1.svg';
import { useSelector } from 'react-redux';
import { getTender } from '../../api/Tender';
import { useNavigate } from 'react-router-dom';

function Tender() {
    const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('tenders');
  const [tenders, setTenders] = useState([]);
    const token = useSelector(state => state.login.token);


  // Function to get status button class
  const getStatusClass = (status) => {
    switch (status) {
      case 'upcoming':
        return styles.upcomingStatus;
      case 'running':
        return styles.runningStatus;
      case 'closed':
        return styles.closedStatus;
      default:
        return styles.upcomingStatus;
    }
    };
    

    // fetch tender data
    const fetchTender = async () => {
        try {
            const tenderData = await getTender(token);
            console.log(tenderData.data);
            setTenders(tenderData.data);
        } catch (error) {
            console.error("Error fetching tender:", error);
        }
    }

    useEffect(() => {
        fetchTender();
    }, []);

  return (
    <div className={styles.container}>
      {/* Tab Navigation */}
      <div className={styles.tabContainer}>
        <button 
          className={`${styles.tabButton} ${activeTab === 'tenders' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('tenders')}
        >
          Tenders
        </button>
        <button 
          className={`${styles.tabButton} ${activeTab === 'shortlisted' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('shortlisted')}
        >
          Shortlisted Tenders
        </button>
      </div>

      {/* Tender Cards Grid */}
      <div className={styles.tenderGrid}>
        {tenders.map((tender) => (
          <div key={tender.id} className={styles.tenderCard}>
            <div className={styles.cardHeader}>
              <div className={styles.eventImage}>
                <img src={tenderImg} alt="Event" />
              </div>
            </div>
            <div className={styles.cardBody}>
              <h3 className={styles.eventName}>{tender.eventName.toUpperCase()}</h3>
              <div className={styles.location}>
                <FaMapMarkerAlt className={styles.locationIcon} />
                <span>{tender.city}</span>
              </div>
              <div className={styles.dateRange}>
                {tender.bidStartDate} - {tender.bidEndDate}
              </div>
            </div>
            <div className={styles.cardFooter}>
              <span className={`${styles.statusBadge} ${getStatusClass(tender.status)}`}>
                {tender.status.charAt(0).toUpperCase() + tender.status.slice(1)}
              </span>
              <button className={styles.viewDetailsButton} onClick={() => navigate(`/tenderDetail`, { state: { tenderId: tender.tenderId } })}>View Details</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tender;
