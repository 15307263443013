import React, { useState } from "react";
import styles from "./CreateAlbum.module.css";
import { useSelector } from "react-redux";
import { AddPortfolioImage } from "../../api/portfolio";
import { toast } from "react-toastify";

function AddImage({ onClose, open, updatePortfolio , portfolioId}) {
  const [portfolioImages, setPortfolioImages] = useState([]);
  const token = useSelector((state) => state.login.token);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);

    const imagePreviews = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      url: URL.createObjectURL(file),
    }));
    console.log(imagePreviews);
    setPortfolioImages([...portfolioImages, ...imagePreviews]);
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      if (portfolioImages.length === 0) {
        alert("Please upload images");
        return;
      } else {
            const data = await AddPortfolioImage(token, portfolioId, portfolioImages);
        if (data.message === "success") {
          toast.success(data.message);
          onClose();
          updatePortfolio();
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  if (!open) {
    return null;
  }

  if (loading) {
    return <div className={styles.loading}>Loading...</div>;
  }
  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          ×
        </button>
        <h2>Add Image</h2>
        <div className={styles.formGroup}>
          <small>You can add up to 5 images</small>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => handleImageUpload(e)}
          />
        </div>

        <button
          className={styles.saveButton}
          onClick={handleSave}
          disabled={loading}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default AddImage;
