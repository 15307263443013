import React, { useState, useEffect } from "react";
import "./supercategory.css";
import loginSideScreen from "../../Assest/img/loginSideScreen.svg";
import logo from "../../Assest/img/logo.svg";
import { useNavigate } from "react-router";
import { getSuperCategory, signUp } from "../../api/signup";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../api/login";
import { loginAction } from "../../Reduxstore/login";

function SuperCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const [id, setId] = useState(0);
  const [data, setData] = useState({ Events: [], Rentals: [] });
  const [isButtonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    const Category = async () => {
      const response = await getSuperCategory(token, data);
      if (response.message === "success") {
        setData(response.data);
      }
    };
    Category();
  }, []);

  const handleSelectCategory = async () => {
    const result = await signUp(token, { superCategoryId: id });
    if (result.message === "success") {
      const profile = await fetchProfile(token);
      if (profile.message === "success")
        dispatch(loginAction.userProfile(profile.data));
      navigate("/category");
    }
  };

  const getId = (e) => {
    setId(e.target.id);
    setButtonDisable(false);
  };

  return (
    <div className="category-container">
      <div className="category-left-screen">
        <img src={loginSideScreen} alt="Login Side Screen" />
      </div>
      <div className="category-right-screen">
        <div className="category-form-container">
          <div className="category-web-img">
            <img className="logo" src={logo} alt="Logo" />
          </div>

          <div className="category-section">
            <h2 className="section-title">Events</h2>
            <div className="category-details">
              {data.Events?.map((item) => (
                <button
                  key={item.id}
                  id={item.id}
                  onClick={getId}
                  className={`detail ${+id === item.id ? "selected-category" : ""}`}
                >
                  <img id={item.id} src={item.img} alt={item.name} />
                  {item.name}
                </button>
              ))}
            </div>
          </div>

          <div className="category-section">
            <h2 className="section-title">Rentals</h2>
            <div className="category-details">
              {data.Rentals?.map((item) => (
                <button
                  key={item.id}
                  id={item.id}
                  onClick={getId}
                  className={`detail ${+id === item.id ? "selected-category" : ""}`}
                >
                  <img id={item.id} src={item.img} alt={item.name} />
                  {item.name}
                </button>
              ))}
            </div>
          </div>

          <button
            className="btn-submit"
            onClick={handleSelectCategory}
            disabled={isButtonDisable}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuperCategory;
