import React from 'react';
import InventoryFlow from './Components/pages/inventorypage/inventoryFlow';
import CategoryFlow from './Components/pages/inventorypage/categoryFlow'
import SingleInventoryFlow from './Components/pages/inventorypage/singleInventoryFlow';
import EditInventoryFlow from './Components/pages/inventorypage/editInventoryFlow';
import PartyFlow from './Components/pages/partypages/partyFlow';
import AddPartyFlow from './Components/pages/partypages/AddPartyFlow';
import AddInventoryFlow from './Components/pages/inventorypage/addInventoryFlow';
import OrderFlow from './Components/pages/orderPages/orderFlow';
import SelectDateFlow from './Components/pages/orderPages/selectDateFlow';
import AdditemFlow from './Components/pages/orderPages/addItemFlow';
import ProfileFlow from './Components/pages/profile/profileFlow';
import EditProfileFlow from './Components/pages/profile/editProfileFlow';
import HomePageFlow from './Components/pages/Layout/HomePageFlow';
import EditCreateOrderFlow from './Components/pages/orderPages/editCreateOrderFlow';
import CreateOrderFlow from './Components/pages/orderPages/createOrderFlow';
import OrderDetailFlow from './Components/pages/orderPages/OrderDetailFlow';
import SubscriptionFlow from './Components/pages/subscription/SubscriptionFlow';
import EcomFlow from './Components/pages/ecomPage/EcomFlow';
import EcomCategoryFlow from './Components/pages/ecomPage/CategoryFlow';
import EcomInventoryFlow from './Components/pages/ecomPage/InventoryFlow';
import EcomDetailInventoryFlow from './Components/pages/ecomPage/DetailInventoryFlow'
import ManageInventoryFlow from './Components/pages/ecomPage/ManageInventoryFlow';
import AddEcomInventoryFlow from './Components/pages/ecomPage/EcomAddInventoryFlow'
import EditEcomInventoryFlow from './Components/pages/ecomPage/EditEcomInventoryFlow';
import WishlistFlow from './Components/pages/ecomPage/WishlistFlow';
import PortfolioFlow from './Components/pages/Portfolio/PortfolioFlow';
import ShowAllImageFlow from './Components/pages/Portfolio/ShowAllImageFlow';
import TenderFlow from './Components/pages/tenderPage/TenderFlow';
import TenderDetailFlow from './Components/pages/tenderPage/TenderDetailFlow';

const PrivateRaoute = [
  //dashboard
  { path: "/dashboard", element: <HomePageFlow />, private: true },
  { path: "/dashboard/subscription", element: <SubscriptionFlow />, private: true },


  // inventory
  { path: "/category", element: <CategoryFlow />, private: true },
  { path: "/inventoryDetail", element: <InventoryFlow />, private: true },
  { path: "/editinventory", element: <EditInventoryFlow />, private: true },
  { path: "/singleinventory", element: <SingleInventoryFlow />, private: true },
  { path: "/addinventory", element: <AddInventoryFlow />, private: true },

  // party
  { path: "/party", element: <PartyFlow />, private: true },
  { path: "/addparty", element: <AddPartyFlow />, private: true },

  //profile
  { path: "/profile", element: <ProfileFlow />, private: true },
  { path: "/profile/editProfile", element: <EditProfileFlow />, private: true },
  
  //order
  { path: "/order", element: <OrderFlow />, private: true },
  { path: "/order/createOrder", element: <CreateOrderFlow />, private: true },
  { path: "/order/selectDate", element: <SelectDateFlow />, private: true },
  { path: "/order/addItem", element: <AdditemFlow />, private: true },
  { path: "/order/editCreateOrder", element: <EditCreateOrderFlow />, private: true, },
  {path: "/order/orderDetail", element: <OrderDetailFlow/>,private: true,  },

    //Ecom
    { path: "/ecom", element: <EcomFlow/>, private: true },
    { path: "/ecomcategory", element: <EcomCategoryFlow />, private: true },
    { path: "/ecominventory", element: <EcomInventoryFlow />, private: true },
 { path: "/ecominventorydetail", element: <EcomDetailInventoryFlow />, private: true },
  { path: '/manageInventory', element: <ManageInventoryFlow />, private: true },
  { path: '/EcomAddInventory', element: <AddEcomInventoryFlow />, private: true },
  { path: '/editEcomInventory', element: <EditEcomInventoryFlow />, private: true },
  { path: '/wishlist', element: <WishlistFlow />, private: true },

  //portfolio
  { path: '/portfolio', element: <PortfolioFlow />, private: true },
  { path: '/showallimage', element: <ShowAllImageFlow />, private: true },

  //tender
  { path: '/tender', element: <TenderFlow />, private: true },
  { path: '/tenderDetail', element: <TenderDetailFlow />, private: true },
];

export default PrivateRaoute;
