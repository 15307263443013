import { baseURL } from "../config";
import axios from "axios";
import { toast } from "react-toastify";

  
export async function getTender(token) {
    try {
       

   const response = await axios.get(`${baseURL}tenders`,{
    headers: {
      Authorization: token,
    } 
   })
        if (response && response.status === 200 && response.data.message === 'success') {
            return response.data;
        }else {
            const errorMessage = response.data.errorMessage || "Failed to fetch parties";
            toast.error(errorMessage);
            throw new Error(errorMessage);
         
          }
    } catch (error) {
        
      console.error("Server responded with an error:", error);
      let errorMessage = "Failed to fetch parties";
      
      if (error.response) {
        errorMessage = error.response.data.message || 
                      error.response.data.errorMessage || 
                      "Error fetching party list";
                      
        if (error.response.status === 401) {
          errorMessage = "Session expired. Please login again.";
        }
      } else if (error.request) {
        errorMessage = "Network error. Please check your connection.";
      }

      toast.error(errorMessage);
      throw error;
   
    throw error;
    }
}
export async function getTenderDetail(token,tenderId) {
    try {
       

   const response = await axios.get(`${baseURL}tenders/${tenderId}/details`,{
    headers: {
      Authorization: token,
    } 
   })
        if (response && response.status === 200 && response.data.message === 'success') {
            return response.data;
        }else {
            const errorMessage = response.data.errorMessage || "Failed to fetch parties";
            toast.error(errorMessage);
            throw new Error(errorMessage);
         
          }
    } catch (error) {
        
      console.error("Server responded with an error:", error);
      let errorMessage = "Failed to fetch parties";
      
      if (error.response) {
        errorMessage = error.response.data.message || 
                      error.response.data.errorMessage || 
                      "Error fetching party list";
                      
        if (error.response.status === 401) {
          errorMessage = "Session expired. Please login again.";
        }
      } else if (error.request) {
        errorMessage = "Network error. Please check your connection.";
      }

      toast.error(errorMessage);
      throw error;
   
    throw error;
    }
}