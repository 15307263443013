import React from 'react';
import styles from './DeletePortfolioModal.module.css';

const DeletePortfolioModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalBody}>
          <h2>Are you sure you want to delete this Portfolio?</h2>
          <div className={styles.buttonGroup}>
            <button 
              className={`${styles.button} ${styles.closeButton}`} 
              onClick={onClose}
            >
              Close
            </button>
            <button 
              className={`${styles.button} ${styles.confirmButton}`} 
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePortfolioModal;
