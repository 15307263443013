import React, { useEffect, useState } from "react";
import styles from "./AddEcomInventory.module.css";
import { AddEcomInventoryApi } from "../../api/ecom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {getCategoryList} from '../../api/inventoryAPI'
import { toast } from "react-toastify";
import { Checkbox } from "@headlessui/react";
function AddEcomInventory() {
  const navigate = useNavigate();

  // const [images, setImages] = useState([]);
    const [vendorData, setVendorData] = useState({
      images: [],
      specifications: [{ title: '', detail: '' }],
      productName: '',
      categoryId: '',
      pricing: '',
      discountedPrice: '',
      description: '',
      isNew: 'false'
    });
    // const [updateVendorData, setUpdateVendorData] = useState({ });

  const [category, setCategory] = useState([]);

    const token = useSelector((state) => state.login.token);
    
  //image upload
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    
    if (files.length + vendorData.images.length > 4) { 
      alert("You can only upload up to 4 images");
      return;
    }

    const imagePreviews = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      url: URL.createObjectURL(file)
    }));

    setVendorData(prev => ({
      ...prev,
      images: [...prev.images, ...imagePreviews]
    }));
  };

  // const handleRemoveImage = (index) => {
  //   // Revoke the object URL of the image being removed
  //   URL.revokeObjectURL(images[index]);
  
  //   // Update the images array by filtering out the removed image
  //   setImages((prev) => prev.filter((_, i) => i !== index));
  // };

    
  //set specifivation title and detail

 
  const handleAddRow = (e) => {
    e.preventDefault();
    setVendorData(prev => ({
      ...prev,
      specifications: [...prev.specifications, { title: '', detail: '' }]
    }));
  };

  const handleRowChange = (index, field, value) => {
    setVendorData(prev => {
      const updatedSpecs = [...prev.specifications];
      updatedSpecs[index] = {
        ...updatedSpecs[index],
        [field]: value
      };
      return {
        ...prev,
        specifications: updatedSpecs
      };
    });
  };

  // change input data
  const handleChange = (event) => {
      const { name, value } = event.target;
      setVendorData((prev) => ({ ...prev, [name]: value }));
    };
    
  //category
  useEffect(() => {
    const fetchCategories = async () => {
        
        const categories = await getCategoryList(token);
        setCategory(categories.data);
      };
      fetchCategories()
  }, [])
    
    //createInventory
    const createInventory = async (e) => {
      e.preventDefault()
      try {
        const formData = new FormData();

        // Add basic data
        formData.append('productName', vendorData.productName);
        formData.append('categoryId', vendorData.categoryId);
        formData.append('pricing', vendorData.pricing);
        formData.append('discountedPrice', vendorData.discountedPrice);
        formData.append('description', vendorData.description);
  
        // Add specifications
        formData.append('specifications', JSON.stringify(vendorData.specifications));
        formData.append('isNew', vendorData.isNew);
        // Add images
        vendorData.images.forEach((image, index) => {
          if (image.file) {
            formData.append('productImages', image.file);
          }
        });
  
        await AddEcomInventoryApi(token, formData);
        toast('Inventory created successfully!');
        navigate('/manageInventory');
       
      } catch (error) {
        console.error('Error creating inventory:', error);
        alert('Failed to create inventory.');
      }  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form>
          <div className={styles.section}>
            <h3>Product Info</h3>

            <div className={styles.inputGroup}>
              <label>Product Name</label>
              <input
                type="text"
                name="productName"
                value={vendorData.productName}
                onChange={handleChange}
              />
            </div>

            <div className={styles.inputGroup}>
              <label>Category</label>
              <select
                className={styles.selectBox}
                name="categoryId"
                value={vendorData.categoryId || ''}
                onChange={handleChange}
                          >
                <option value="" disabled>
                  Select Category
                </option>
                {category.map((cat) => (
                  <option key={cat.categoryId} value={cat.categoryId}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            
            <div className={styles.inputGroup}>
              <label>Product Media</label>
              <p className={styles.mediaNote}>You can add up to 4 images</p>
              <div className={styles.imageUpload}>
                {vendorData.images.map((img, index) => (
                  <div key={index} className={styles.previewImage}>
                        <img src={img.preview} alt={`Preview ${index + 1}`} />
                        {/* <button className={styles.remove} onClick={() => handleRemoveImage(index)}>Remove</button> */}

                  </div>
                ))}
                {vendorData.images.length < 4 && (
                  <div className={styles.uploadButton}>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleImageUpload}
                      name="productImages"
                    />
                    <span>Upload Image</span>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.input}>
              <div>
                <label>Pricing</label>
                <input
                  type="number"
                  name="pricing"
                  value={vendorData.pricing}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Discounted Price</label>
                <input
                  type="number"
                  name="discountedPrice"
                  value={vendorData.discountedPrice}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={styles.inputGroup}>
              <label>Description</label>
              <textarea
                name="description"
                value={vendorData.description}
                onChange={handleChange}
              />
            </div>

            <div className={styles.productDetails}>
              <h2>Product Details</h2>
              <div className={styles.tabletitle}>
                <p>Title</p>
                <p>Detail</p>
              </div>
              <div className={styles.detailsTable}>
                {vendorData.specifications.map((spec, index) => (
                  <div className={styles.detailRow} key={index}>
                    <div className={styles.detailTitle}>
                      <input
                        type="text"
                        value={spec.title}
                        onChange={(e) => handleRowChange(index, 'title', e.target.value)}
                        placeholder="Enter title"
                      />
                    </div>
                    <div className={styles.detailValue}>
                      <input
                        type="text"
                        value={spec.detail}
                        onChange={(e) => handleRowChange(index, 'detail', e.target.value)}
                        placeholder="Enter detail"
                      />
                    </div>
                  </div>
                ))}
                <button onClick={handleAddRow} className={styles.addRowButton}>Add</button>
              </div>
            </div>
            <div className={styles.buttonGroup}>
              <button
                type="submit"
                className={styles.createButton}
                onClick={createInventory}
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddEcomInventory;
