import React, { useState, useEffect } from 'react';
import styles from './TenderDetail.module.css';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTenderDetail } from '../../api/Tender';

function TenderDetail() {

  const token = useSelector(state => state.login.token);
  const location = useLocation();
    const tenderId = location.state?.tenderId;
    const [tenderData, setTenderData] = useState({eventName:''});
  
    console.log(tenderId);
  useEffect(() => {
    const fetchTenderDetails = async () => {
      if (tenderId) {
        try {
          const response = await getTenderDetail(token, tenderId)
            setTenderData(response.data[0]);
          console.log(response.data);
        } catch (error) {
          console.error("Error fetching tender details:", error);
        }
      }
    };
    
    fetchTenderDetails();
  }, [tenderId]);

  return (
    <div className={styles.container}>
      <h1 className={styles.pageTitle}>Tender Detail</h1>
      
      <div className={styles.detailCard}>
        <div className={styles.headerSection}>
          <div className={styles.leftSection}>
            <h2 className={styles.eventName}>{tenderData.eventName.charAt(0).toUpperCase() + tenderData.eventName.slice(1)}</h2>
            <p className={styles.location}>{tenderData.city}</p>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.dateInfo}>
              <p className={styles.dateLabel}> Bid Start date :</p>
              <p className={styles.dateValue}>{tenderData.bidStartDate}</p>
            </div>
            <div className={styles.dateInfo}>
              <p className={styles.dateLabel}> Bid End date : </p>
              <p className={styles.dateValue}>{tenderData.bidEndDate}</p>
            </div>
          </div>
        </div>
        
        <div className={styles.categorySection}>
          <h3 className={styles.sectionTitle}>Event Rental Description</h3>
        <p className={styles.descriptionText}>{tenderData.eventRentalDescription}</p>
          
        </div>
        
        <div className={styles.descriptionSection}>
          <h3 className={styles.sectionTitle}>Description</h3>
          <p className={styles.descriptionText}>{tenderData.description}</p>
        </div>
      </div>
      
    
    </div>
  );
}

export default TenderDetail;
