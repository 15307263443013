import React, { useState, useEffect } from "react";
import "./signUp.css";
import loginSideScreen from "../../Assest/img/loginSideScreen.svg";
import logo from '../../Assest/img/logo.svg';
import { signUp } from "../../api/signup";
import { useSelector } from "react-redux";
import SuperCategory from "./SuperCategory";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { loginAction } from '../../Reduxstore/login';
import { useDispatch } from "react-redux";
    
function SignUp() { 
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [vendorData, setVendorData] = useState({
        storeName: '', email: '',
        pincode: null, city: '', state: ''
    })

    const [renderCategory, setRenderCategory] = useState(false)
    const [isButtonDisable , setButtonDisable] = useState(true)
  
    const token = useSelector((state)=>state.login.token)

    useEffect(() => {
        if (vendorData.storeName && vendorData.email && vendorData.pincode && vendorData.city && vendorData.state !== null) {
            setButtonDisable(false)
        }
        else {
            setButtonDisable(true)
        }
    },[vendorData])

    
        const fetchSignUp = async() => {
            const signUpResult = await signUp(token, vendorData)
            if (signUpResult.message === 'success') {
                setRenderCategory(true)
                
            }
        }
        
        const handleChange = (event) => {
            const { name, value } = event.target
            
          

        setVendorData((pre) => ({
            ...pre,
            [name]:value
        }))
    }
    const handleLogin = () => {
        localStorage.removeItem("token");
        dispatch(loginAction.logOut())
navigate('/')
    }

    return (
        <>
            {renderCategory ? <SuperCategory data={vendorData} /> : (<div className="signUp-container d-flex justify-content-center align-items-center">
      <div className="signUp-left-screen">
        <img src={loginSideScreen} />
      </div>
      <div className="signUp-right-screen">
        <div className="signUp-form-container">
          
                  <div className="signUp-web-img">
                      <img  className='logo' src={logo} />
                  </div>
                  <div className="signUp-head">
                      Sign Up Details
                  </div>
                  <div className="signUp-details">
                      <div className="details">
                          
                      <label>Enter Store Name</label>
                          <input placeholder="Store Name"  name='storeName'type="text" value={vendorData.storeName} onChange={ handleChange} required></input>
                      <label>Enter Mail ID</label>
                      <input placeholder="Email ID" name='email' value={vendorData.email} type="email" onChange={ handleChange} required></input>
                      <label>Enter Pin Code</label>
                      <input placeholder="Pin Code" name='pincode' type="number" value={vendorData.pincode} onChange={ handleChange} required></input>

                      </div>
                      <div className="location">
                      <label>City</label>
                          <input placeholder="City" name='city' value={vendorData.city} type="text"onChange={ handleChange} required></input>
                          <label>State</label>
                          <input placeholder="State" name='state' type="text" value={vendorData.state} onChange={ handleChange} required></input>
                      </div>
                  </div>

          <div className="btn-signUp">
                            <button disabled={isButtonDisable} onClick={fetchSignUp}>Submit
            </button>
                        </div>
                        <p className="text-center ">Already have an account? <span className="signup-link" onClick={handleLogin}>Login</span></p>
        </div>
      </div>
    </div>)}
                </>
  );
}

export default SignUp;
