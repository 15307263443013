import React, { useEffect, useState } from "react";
import styles from "./editprofile.module.css";
import { useSelector } from "react-redux";
import { fetchProfile, editProfile } from "../../api/login"; // Import both functions
import { useNavigate } from "react-router";
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";

function EditProfile() {
  const token = useSelector((state) => state.login.token);
  const navigate= useNavigate()
  const [profileData, setProfileData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    state: '',
    pincode: '',
    gst: '',
    city: '',
    planNumber: '',
    doc: '',
    profileImg: '',
    logo: '',
    signature: '',
    tAndC:''
  });
  const [updateProfileData , setUpdateProfileData] = useState({});

  useEffect(() => {
    const getProfile = async () => {
      const result = await fetchProfile(token);
      setProfileData(result.data);
    };
    getProfile();
  }, [token]);

  const handleLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newLogoImg = e.target.result;

        setProfileData((prev) => ({
          ...prev,
          logo: newLogoImg,
        }));
        setUpdateProfileData((prevUpData) => ({
          ...prevUpData,
          logo: newLogoImg,
        }));
        console.log(setUpdateProfileData)
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  

  const handleSignatureChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newSignatureImg = e.target.result;

        setProfileData((prev) => ({
          ...prev,
          signature: newSignatureImg,
        }));
        setUpdateProfileData((prevUpData) => ({
          ...prevUpData,
          signature: newSignatureImg,
        }));
        console.log(setUpdateProfileData)
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name , value)
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setUpdateProfileData((prevUpData) => ({
      ...prevUpData,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    try {
      await editProfile(token, updateProfileData);
      // Re-fetch updated profile data after saving
      const updatedProfile = await fetchProfile(token);
      navigate('/profile')
      setProfileData(updatedProfile.data); // Update the profile data in the state
      console.log(updatedProfile)
    } catch (error) {
      console.error("Error saving profile details:", error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.personInfo}>
        <div className={styles.info}>
          <div className={styles.profileimg}>
            <img className={ styles.img} src={profileData.logo} alt="profile" />
            <input type="file" onChange={handleLogoChange}></input>
          </div>

          <div className={styles.name}>
            <p>Owner Name</p>
            <input onChange={handleChange} name="name" value={profileData.name || ''} />
            <p>Store Name</p>
            <input onChange={handleChange} name="storeName" value={profileData.storeName || ''} />
          </div>
        </div>

        <div className={styles.column}>
          <p>Business Phone Number</p>
          <input type='number' onChange={handleChange} name="phone" value={profileData.phone || ''} />
        </div>

        <div className={styles.column}>
          <p>Business Email</p>
          <input onChange={handleChange} name="email" value={profileData.email || ''} />
        </div>

        <div className={styles.column}>
          <p>GST Number</p>
          <input onChange={handleChange} name="gst" value={profileData.gst || ''} />
        </div>

        <div className={styles.column}>
          <p>PAN Number</p>
          <input  onChange={handleChange} name="pan" value={profileData.pan || ''} />
        </div>

        <div className={styles.column}>
          <p>Business Address</p>
          <input onChange={handleChange} name="address" value={profileData.address || ''} />
        </div>

        <div className={styles.type}>
          <p>State</p>
          <input onChange={handleChange} name="state" value={profileData.state || ''} />
          <p>City</p>
          <input onChange={handleChange} name="city" value={profileData.city || ''} />
          <p>Pincode</p>
          <input onChange={handleChange} name="pincode" value={profileData.pincode || ''} />
        </div>

        <div className={styles.column}>
          <p>Terms and Conditions</p>
          <input maxLength={500} onChange={handleChange} name="tAndC" value={profileData.tAndC || ''} />
        </div>

        <div className={styles.column}>
          <p>Website</p>
          <input onChange={handleChange} name="website" value={profileData.website || ''} />
        </div>

        <div className={styles.column}>
          <p>Social Sites</p>
          <input onChange={handleChange} name="socialSites" value={profileData.socialSites || ''} />
        </div>

        <div className={styles.signature}>
          <p>Signature</p>
          <img className={styles.imgsig} src={profileData.signature} alt="signature" />
          <input type="file" onChange={handleSignatureChange}></input>
        </div>
        
        <div className={styles.saveCancel}>
          <button className={styles.save} onClick={handleEdit}>
            <img src={save} alt="Save" /> Save
          </button>
          <button className={styles.cancel}  onClick={()=>navigate('/profile')}>
            <img src={cancel} alt="Cancel" /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
