import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import './App.css'
import PrivateRoute from "./PrivateRaoute";
import SplashScreen from "./Components/Login/SplashScreen";
import SignUp from "./Components/Login/SignUp";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoginFlow from "./Components/Login/LoginFlow";
import { fetchProfile } from "./api/login";
import { loginAction } from "./Reduxstore/login";
import PrivacyPolicy from "./Policy/PrivacyPolicy";
import TrialModal from './Components/auth/TrialModal'
import TermAndCondition from "./Policy/TermAndCondition";
import ContactUs from "./Policy/ContactUs";

function App() {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);

  const login = useSelector((state) => state.login.login);
  const token = useSelector((state) => state.login.token);
  const userProfile = useSelector((state) => state.login.userProfile);
  const plan = useSelector((state) => state.login.plan);

  const dispatch = useDispatch()

  useEffect(() => {
    
    
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setShowSplashScreen(false);
      }, 1000); 
    }, 3000); 

    const profile= async() =>{

      if (token) {
        const profile = await fetchProfile(token)
        dispatch(loginAction.plan(profile.data.trialPlan))
        dispatch(loginAction.userProfile(profile.data))

        if (plan === 1 ){
                 setShowTrialModal(true);
        }
      }
    }
    profile();
   
    return () => clearTimeout(timer);
  }, [token]);

  
  
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            !login ? (
              showSplashScreen ? (
                <SplashScreen fadeOut={fadeOut} />
              ) : (
                <LoginFlow />
              )
            ) : (
              showSplashScreen ? (
                <SplashScreen fadeOut={fadeOut} />
              ) : login && userProfile.newUser ? (
                <SignUp />
              ) : (
                <Navigate to="/dashboard" />
              )
            )
          }
          
        />
       <Route  path="/privacyPolicy"
          element={
            <PrivacyPolicy/>
          } />
        <Route  path="/termAndCondition"
          element={
            <TermAndCondition/>
          } />
        <Route  path="/contactUs"
          element={
            <ContactUs/>
          } />
        

        {PrivateRoute.map((route, index) => {
          const { path, element, private: isPrivate } = route;
          return (
            <Route
              key={index}
              path={path}
              element={
                isPrivate ? ((login && !userProfile.newUser) ? element : <Navigate to="/" />) : element
              }
            />
          );
        })}
  
        
      </Routes>
      {showTrialModal && (
        <TrialModal onClose={() => setShowTrialModal(false)} />
      )}
 <ToastContainer/>
 </> );
}

export default App;
