import React, { useState } from "react";
import styles from "./ShowAllImage.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllPortfolioImages, deletePortfolioImage } from "../../api/portfolio";
import { useEffect } from "react";
import deletePortfolio from "../../Assest/img/deletePortfolio.svg";
import { toast } from "react-toastify";
import AddImage from "./AddImage";
import AddVideo from "./AddVideo";
import youTubeIcon from "../../Assest/img/youTubeIcon.svg";

function ShowAllImage() {
    const navigate = useNavigate();
    const location = useLocation();
  const portfolioId = location.state.id;
  
  console.log(portfolioId);
  
  const [activeTab, setActiveTab] = useState("portfolio");
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddImageOpen, setIsAddImageOpen] = useState(false);
  const [portfolioImageData, setPortfolioImageData] = useState([]);   
  const [isAddVideoOpen, setIsAddVideoOpen] = useState(false);
  const token = useSelector((state) => state.login.token);

  const fetchPortfolio = async () => {    
    try {
      const data = await getAllPortfolioImages(token,portfolioId);
      setPortfolioImageData(data.data);
      console.log(data.data);
    } catch (error) {
      console.error('Error fetching portfolio:', error);
    }
  }

  
  useEffect(() => {
    fetchPortfolio();   
  }, []);

  const updatePortfolio = async () => {
    await fetchPortfolio();
  }

  const handleAddImage = () => {
    setIsAddImageOpen(true);
  };

  const closeAddImage = () => {
    setIsAddImageOpen(false);
  };

  const handleDeleteImage = async (portfolioId, imageId) => {
    console.log(portfolioId, imageId);
    try {   
   const  response = await deletePortfolioImage(token, portfolioId, imageId);
      console.log(response);
      if(response.status === 200){
        toast.success("Image deleted successfully");
        fetchPortfolio();
      }else{
        toast.error(response.message);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error('Error deleting image');
    }
  }

  const handleAddVideo = () => {
    setIsAddVideoOpen(true);
  };

  const closeAddVideo = () => {
    setIsAddVideoOpen(false);
  };

  const handleVideoClick = (videoLink) => {
    window.open(videoLink);
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.tabs}>
          <button
            className={`${styles.tab} ${
              activeTab === "portfolio" ? styles.activeTab : ""
            }`}
            onClick={() => setActiveTab("portfolio")}
          >
            Portfolio
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "verification portfolio" ? styles.activeTab : ""
            }`}

            onClick={() => setActiveTab("verification portfolio")}
          >
            Verification Portfolio
          </button>
        </div>

        <div className={styles.searchBar}>
          <input
            type="search"
            placeholder="Search"
            value={searchTerm}
            key={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className={styles.categoryGrid}>
        
        <div className={styles.categoryCard} onClick={handleAddImage}>
          <div className={styles.plusIcon}>+</div>
          <p>Add Image</p>
        </div>

        <div className={styles.categoryCard} onClick={handleAddVideo}>
          <div className={styles.plusIcon}>+</div>
          <p>Add Video</p>
        </div>

        {portfolioImageData.map((item) => (
          <div key={item.id} className={styles.categoryCard}>
            <img className={styles.deletePortfolio} src={deletePortfolio} onClick={() => handleDeleteImage(item.portfolioId,item.imageId)}></img>
            <img
              src={item.imageUrl}
              alt={item.name}
              className={styles.categoryImage}
            />
            {
              item.type === "video_link" ? <div className={styles.videoIcon}>
                <img src={youTubeIcon} alt="video" />
                <p onClick={()=>handleVideoClick(item.videoLink)}>{ item.videoLink}</p>
              </div> : null
            }
          
          
          </div>
        ))}
      </div>
      <AddImage onClose={closeAddImage} open={isAddImageOpen} updatePortfolio={updatePortfolio} portfolioId={portfolioId} />
      <AddVideo onClose={closeAddVideo} open={isAddVideoOpen} updatePortfolio={updatePortfolio} portfolioId={portfolioId} />
    </div>
  );
}

export default ShowAllImage;
