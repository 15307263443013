import React  from 'react';
import Layout from '../Layout/Layout';
import Portfolio from '../../Portfolio/Portfolio';

function PortfolioFlow() {


  return (
    <Layout  child={'Portfolio'}>
    <Portfolio />
  </Layout>
  )
}

export default PortfolioFlow
